import axios from "axios";
import jwt_decode from "jwt-decode"
import store from '@/store'

import {unHighlightBuilderServices} from '@/services/pageBuilder.service.js'
import {unHighlightExtServices} from '@/services/externalSources.service.js'
import {unHighlightConditionsRulesService} from '@/services/conditionsRules.service.js'

let isStage = false;

if (window.location.href.includes('stage')) {
  isStage = true;
}

const INSTANCE_URLS = {
  mexico: 'https://mxco-card.api.stage.bpms.studio',
  india: 'https://api.stage.bpms.studio',
  pakistan: 'https://pk.api.stage.bpms.studio',
  demo: 'https://demo.api.bpms.studio'
}

const defaultConfig = {
    baseURL: process.env.VUE_APP_BACKEND_URL,
}

/** default api instance */
const defaultApiInstance = axios.create(defaultConfig);

/** auth api instance */
const authApiInstance = axios.create(defaultConfig);

authApiInstance.interceptors.request.use(config => {
  if (isStage) {
    config.baseURL = INSTANCE_URLS[localStorage.getItem('admin-api-url')];
  }
  return config;
})

/** add necessary headers and refresh token before request if it exp  */
defaultApiInstance.interceptors.request.use(async config => {
  if (isStage) {
    config.baseURL = INSTANCE_URLS[localStorage.getItem('admin-api-url')];
  }
    try {
        let authToken = localStorage.getItem('accessToken') || '';
        const defaultHeaders = {
            Authorization: `Bearer ${authToken}`
        }

        /**
         * get token and decode it
         */
        let token = localStorage.getItem('accessToken');
        let decoded = jwt_decode(token);

        /**
         * get current date and date of token exp
         */
        let expTime = decoded.exp * 1000;
        let curTime = new Date().getTime();

        /**
         * compare times and refresh token if it necessary
         */
        if (expTime - curTime <= 10000) {
            const refreshResponse = await store.dispatch('authentication/refresh', localStorage.getItem('refreshToken'));
            defaultHeaders.Authorization = `Bearer ${refreshResponse.access}`;
        }

        /**
         * rewrite config headers
         */
        config.headers = {...config.headers, ...defaultHeaders};
    } catch (err) {
        console.log("defaultInstance.interceptors.request.use", err);
    }
    return config;
}, error => {
    console.log('error request - ', error);
});

const services = [
    ...unHighlightBuilderServices, 
    ...unHighlightExtServices, 
    ...unHighlightConditionsRulesService
]

/** add handler for request results or errors  */
defaultApiInstance.interceptors.response.use(
    response => {
      const unHighlight = services.find(item => {
        return response.config.url.includes(item.url) && item.method === response.config.method
      })

      if (response.data?.message && !unHighlight) {
          store.dispatch('alert/success', response.data.message);
      } 
      /** result handler */
      return {...response.data, statusCode: response.status};
    },

    async error => {
      const unHighlight = services.find(item => {
        return error.config.url.includes(item.url) && item.method === error.config.method
      })
      /** error handler and refresh token with reload page if it wasn't refreshed before request */
      const originalRequest = error.config;
        if (error.response?.status === 401 && error.response.data.response.code.includes("token") && !originalRequest._retry) {
            originalRequest._retry = true;
            store.dispatch('authentication/refresh', localStorage.getItem('refreshToken')).then((resp) => {
                originalRequest.headers.Authorization = `Bearer ${resp.access}`;
                window.location.reload();
            })
        }

       if(!unHighlight) await store.dispatch('alert/error', `${new Error(error?.response?.data?.message || error)}`);

    }
);

/** auth response handler */
authApiInstance.interceptors.response.use(
    response => {
        return response.data;
    },
    async error => {
        await store.dispatch('alert/error', `${new Error(error?.response?.data?.message || error)}`);
        await store.dispatch('authentication/logout');
    }
)

export { defaultApiInstance, authApiInstance };

